
import tasqsListModule from '@/store/modules/tasqsListModule';
import { Component, Vue } from 'vue-property-decorator';
import DropdownPlaceholder from '@/components/tasqsCommon/DropdownPlaceholder.vue';
import { getComponent } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    DropdownPlaceholder,
    NotificationHeader: () => getComponent('notifications/NotificationHeader'),
    NotificationFilter: () => getComponent('notifications/NotificationFilter'),
    NotificationSetting: () =>
      getComponent('notifications/NotificationSetting'),
  },
})
export default class NotificationList extends Vue {
  view = 'notifications';
  filteringDefaultWorking = {
    selectedWells: [],
    selectedJobTypes: [],
    startDate: null,
    endDate: null,
  };
  filteringWorking = { ...this.filteringDefaultWorking };

  get checkAnyKeyHasValue() {
  return Object.values(this.filteringWorking).some((value) => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
    if (typeof value === 'string' && value.length > 0) {
      return true;
    }
    return false;
  });
}


  get notificationUnReadCount(){
    return workflowModule.userUnReadNotificationCount;
  }

  get rawUserNotifications() {
    const notifications = workflowModule.userNotifications;
    return notifications.map((notification) => ({
      ...notification,
      createdBy: notification.createdBy ? getNameByEmail(notification.createdBy) : null,
      rawTime: notification.rawTime,
      time: this.formatDate(notification.time),
    }));
  }

  get userNotifications() {
    let formattedNotifications = this.rawUserNotifications;

    if (this.checkAnyKeyHasValue) {
      const { selectedWells, selectedJobTypes, startDate, endDate } =
        this.filteringWorking;

      if (selectedWells.length) {
        formattedNotifications = formattedNotifications.filter(
          (notification) =>
            notification.nodeName &&
            selectedWells.includes(notification.nodeName)
        );
      }

      if (selectedJobTypes.length) {
        formattedNotifications = formattedNotifications.filter(
          (notification) =>
            notification.predictionType &&
            selectedJobTypes.includes(notification.predictionType)
        );
      }

      if (startDate) {
        const formattedStartDate = startDate;
        formattedNotifications = formattedNotifications.filter(
          (notification) =>
            notification.rawTime &&
            formattedStartDate <= notification.rawTime.split('T')[0]
        );
      }

      if (endDate) {
        const formattedEndDate = endDate;
        formattedNotifications = formattedNotifications.filter(
          (notification) =>
            notification.rawTime &&
            formattedEndDate >= notification.rawTime.split('T')[0]
        );
      }
    }

    return formattedNotifications.sort((a, b) => b.timestamp - a.timestamp);
  }

  get headerText() {
    if (this.isFilteringView) {
      return 'Filters';
    } else if (this.isSettingsView) {
      return 'Settings';
    } else {
      return 'Notifications';
    }
  }

  get isFilteringView() {
    return this.view === 'filtering';
  }

  get isSettingsView() {
    return this.view === 'settings';
  }

  get isNotificationsView() {
    return this.view === 'notifications';
  }

  toggleView(view) {
    this.view = view;
  }

  actionHandler() {
    if (this.isNotificationsView) {
      this.toggleNotificationList();
    } else {
      this.toggleView('notifications');
    }
  }

  toggleNotificationList() {
    tasqsListModule.toggleNotificationList();
  }

  testing(event) {
    console.log(event);
  }

  notificationTypeTxt(type) {
    if (type === 'TASQ_REASSIGNED_TO_ME') {
      return ' was reassigned to you';
    } else if (type === 'OFF_TARGET_TASQ_ASSIGNED_TO_ME_RETURN_TO_NORMAL') {
      return ' assigned to you returned to target';
    } else {
      return ' was assigned to you';
    }
  }

  async markNotificationAsRead(notificationID = null) {
    await workflowModule.updateUserNotificationStatus(notificationID);
    workflowModule.setUserNotificationsReadStatus(notificationID);
  }

  async goToTasqPage({ notificationID, predictionID, markAsRead }) {
    if (!markAsRead) {
      await this.markNotificationAsRead(notificationID);
    }

    const currentRouteName = 'Tasqs';

    this.$router.push({
      name: currentRouteName,
      params: { id: predictionID },
      query: { type: 'id', view: 'tasq' },
    });

    tasqsListModule.setIsEditing(false);
    assetsModule.setActiveProducingTasq('');
    tasqsListModule.setActiveTasq(predictionID || '');
    this.toggleNotificationList();
  }

  formatDate(date) {
    const now = new Date();
    const diff = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diff < 60) {
      return 'Just now';
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      return `Today &bull; ${minutes} min ago`;
    } else if (this.isSameDay(now, date)) {
      const formattedTime = this.formatTime(date);
      return `Today &bull; ${formattedTime}`;
    } else if (this.isYesterday(now, date)) {
      const formattedTime = this.formatTime(date);
      return `Yesterday &bull; ${formattedTime}`;
    } else {
      const daysAgo = Math.floor(diff / 86400);
      const formattedTime = this.formatTime(date);
      return `${daysAgo} days ago &bull; ${formattedTime}`;
    }
  }

  isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  isYesterday(date1, date2) {
    const yesterday = new Date(date1);
    yesterday.setDate(yesterday.getDate() - 1);
    return this.isSameDay(yesterday, date2);
  }

  formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }

  resetFilters() {
    this.filteringWorking = { ...this.filteringDefaultWorking };
  }

  applySettings() {
    // @ts-ignore
    this.$refs['notification-settings'].saveSettings();
  }

  applyFilters() {
    // @ts-ignore
    this.$refs['notification-filters'].saveFiltering();
  }
}
